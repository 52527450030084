import React, { useEffect, useState } from "react";
import anime from "animejs/lib/anime.es.js";
import RegisterModal from "../../components/Common/modals/RegisterModal";
import RegisterForm from "../../components/Register/RegisterForm";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import loader from '../../assests/images/purple-loader.gif'
const RegisterLayout = () => {
  const location = useLocation();
  let placement = location?.state?.parent;
  let position = location?.state?.position;
  let dummyUserId = location?.state?.dummyUserId ?? false;

  const stepsLabel = [
    { step: "01", label: "pick_your_products" },
    { step: "02", label: "contact_information" },
    { step: "03", label: "login_information" },
    { step: "04", label: "over_view" },
    { step: "05", label: "payment" },
  ];
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const { t } = useTranslation();

  const registerFields = ApiHook.CallRegisterFields();
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );

  const handleToggleRegisterModal = () => {
    setShowRegisterModal(!showRegisterModal);
  };

  // animation for the register
  const animateStepTransition = (nextStep) => {
    const containerElement = document.querySelector("#animation");
    const nextStepElement = document.querySelector(
      `.register-left-cnt-row:nth-child(${nextStep + 1})`
    );

    // Set initial position of the next step
    anime.set(containerElement, { opacity: 0 });
    anime.set(nextStepElement, { translateY: "100%" });

    // Animate the next step in
    anime({
      targets: containerElement,
      opacity: [0, 1],
      duration: 1000,
      easing: "easeInOutQuad",
    });
    anime({
      targets: nextStepElement,
      translateY: "0%",
      duration: 500,
      easing: "easeInOutQuad",
    });
  };

  // No product and regFee
  useEffect(() => {
    if ((registerFields?.data?.regPack === null || registerFields?.data?.regPack.length === 0) && (registerFields?.data?.regData === 0 || registerFields?.data?.regData === null)) {
      setActiveStep(2);
    } else {
      setActiveStep(1);
    }
  }, [registerFields?.data?.regPack])
console.log("=======fetching=====",registerFields.isFetching)
  return (
    <>
      <div className="page_head_top">{t("register")}</div>
      {
        registerFields.isLoading?
        <span style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: 0
        }}>
          <img src={loader} className="w-25"/>
        </span>
       :
       <>
       {registerFields?.data?.registrationAllowed ? <>
        <div className="container ">
          <div className="register_row align-items-center justify-content-center">
            <div className="main-regsiter-left-section">
              <div className="register-left-cnt-row opacity-1">
                <h2>{t("registerNow")}</h2>
              </div>
              {/* {registerFields.isLoading &&
              stepsLabel?.map((step, index) => (
                <div
                  key={index}
                  className={`register-left-cnt-row ${
                    index === activeStep - 1 ? "active" : ""
                  }`}
                >
                  <span>{step.step}</span> {t(`${step.label}`)}
                </div>
              ))} */}
              {registerFields?.data?.registrationSteps?.map((step, index) => (
                <div
                  key={index}
                  className={`register-left-cnt-row ${((registerFields?.data?.regData === 0 || registerFields?.data?.regData === null) && (registerFields?.data?.regPack === null || registerFields?.data?.regPack.length === 0)) ? (step.step === activeStep - 1 ? "active" : "") : (step.step === activeStep ? "active" : "")}`}
                >
                  <span>{step.step}</span> {t(`${step.label}`)}
                </div>
              ))}
            </div>
            <RegisterForm
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              handleToggleRegisterModal={handleToggleRegisterModal}
              animateStepTransition={animateStepTransition}
              data={registerFields?.data}
              isLoading={registerFields.isFetching}
              currency={userSelectedCurrency}
              placement={placement}
              position={position}
              dummyUserId={dummyUserId}
            />
          </div>
        </div>
        <RegisterModal
          show={showRegisterModal}
          handleClose={handleToggleRegisterModal}
          data={registerFields?.data?.termsAndCondition?.termsAndConditions}
        />
      </> :
        <div>
          {!registerFields.isLoading && <div className="container ">
            <div className="register_row align-items-center justify-content-center">
              <div className="col-md-12">
                <div className="nodata-table-view">
                  <div className="nodata-table-view-box">
                    <div className="nodata-table-view-box-img">
                      <img src="/images/temporary.png" alt="" />
                    </div>
                  </div>
                  <div className="reg-block-text">
                    {t("registration_blocked")}
                  </div>
                </div>
              </div>
            </div>
          </div>}
        </div>
      }
       </>
      }
      

      
    </>
  );
};

export default RegisterLayout;