import React, { useState } from 'react'
import SubmitButton from '../Common/buttons/SubmitButton'
import { useTranslation } from 'react-i18next'
import moment from 'moment/moment'
import Select from "react-select";
import { useSelector } from "react-redux";
import CurrencyConverter from '../../Currency/CurrencyConverter';
import { ApiHook } from '../../hooks/apiHook';
import { toast } from 'react-toastify'
import { useQueryClient } from "@tanstack/react-query";
import ListGroup from 'react-bootstrap/ListGroup';

function SubscriptionDetails({ subscriptionDetails }) {
    console.log("===subscriptionDetails===", subscriptionDetails.paymentMode)
    const requestApi = ApiHook.CallRequestSubscriptionAction()
    const { t } = useTranslation()
    const queryClient = useQueryClient();
    const actions = subscriptionDetails.paymentMode === "oneTime"
        ? [{ label: "Refund", value: "refund" }]
        : [
            { label: "Cancel", value: "cancel" },
            { label: "Refund", value: "refund" }
        ];

    const [selectedAction, setselectedAction] = useState(null)
    const conversionFactor = useSelector(
        (state) => state?.user?.conversionFactor
    );
    const currency = useSelector(
        (state) => state.user?.selectedCurrency
    );

    const submitHandler = async () => {
        const data = {
            subscriptionId: subscriptionDetails.subscriptionId ?? null,
            paymentMode: subscriptionDetails.paymentMode,
            transactionId: subscriptionDetails.transactionId ?? null,
            action: selectedAction?.value,
            paymentMethod: subscriptionDetails.paymentMethod,
            amount: subscriptionDetails?.amount
        }
        const response = await requestApi.mutateAsync(data)
        if (response.status) {
            console.log("===response====", response)
            queryClient.refetchQueries({ queryKey: ["profile"] });
            toast.success(t("subscriptionRqst", { action: selectedAction.action }))
            setselectedAction(null)

        } else {
            toast.error(t("requstFailed"))
        }
    }
    return (
        <div id="fifthTab" className="tabcontent">

            <h3>{t("subscriptionDetails")}</h3>
            <div className="tabcontent_form_section">
                <div className="mb-3 row tabBlockClass">
                    <ListGroup variant="flush" as="ul" className="list-group-square">
                        <ListGroup.Item>{t("status")}  : <span className='subcription-list-item'>active</span></ListGroup.Item>
                        <ListGroup.Item>{t("package")} : <span className='subcription-list-item'>{subscriptionDetails["Package.name"]}</span></ListGroup.Item>
                        <ListGroup.Item>{t("paymentMethod")} : <span className='subcription-list-item'>{subscriptionDetails["paymentMethod"] === 5 ? "Stripe" : "Paypal"}</span></ListGroup.Item>
                        <ListGroup.Item>{t("Amount")} : <span className='subcription-list-item'>
                            {currency?.symbolLeft}{" "}
                            {CurrencyConverter(
                                subscriptionDetails.amount,
                                conversionFactor
                            )}
                        </span></ListGroup.Item>
                        <ListGroup.Item>{t("activatedOn")} : <span className='subcription-list-item'>{moment(subscriptionDetails["createdAt"]).format('DD-MM-YYYY')}</span></ListGroup.Item>
                    </ListGroup>


                </div>
                {
                    (subscriptionDetails.actionStatus && (!subscriptionDetails.isUserAlreadyRqstd)) &&
                    <>
                        <label>*Actions</label>
                        <Select
                            isDisabled={requestApi.isLoading}
                            options={actions}
                            onChange={(value) => setselectedAction(value)}
                            value={selectedAction}
                        />
                    </>


                }
                <div
                    className={`paymenytLinkBtn`}
                >
                    {
                        selectedAction &&
                        <SubmitButton
                            id={"1"}
                            isSubmitting={requestApi.isLoading}
                            click={submitHandler}
                            text={`${selectedAction?.label} Request`}
                            className="btn mt-3"
                        />
                    }

                </div>
            </div>
        </div>
    )
}

export default SubscriptionDetails