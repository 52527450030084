import React from 'react'
import { useTranslation } from 'react-i18next';

export default function NoData() {
    const { t } = useTranslation();
    return (
        <div className="nodata-promotion-view">
            <div className="nodata-table-view-box">
                <div className="nodata-table-view-box-img">
                    <img src="/images/no-data-image1.jpg" alt="" />
                </div>
                <div className="nodata-table-view-box-txt">
                    {t("sorry_no_data_found")}
                </div>
            </div>
        </div>
    )
}
