import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SubmitButton from "../Common/buttons/SubmitButton";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import Select from "react-select";
import { useQueryClient } from "@tanstack/react-query";

const PaymentDetailsTab = ({ payment }) => {
  console.log("==payment==============",payment)
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [paymentMethodType, setPaymentMethodType] = useState(payment?.id ?? 4);
  const [paymentValue, setPaymentValue] = useState(payment?.value);

  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
  const [error, setError] = useState("");
  const [connectStripe, setConnectToStripe] = useState(false);
  const [updateBtn, setUpdateButton] = useState(false);


  const paymentMutation = ApiHook.CallPaymentDetails();
  const connectTostripe = ApiHook.CallConnectTostripe(connectStripe)
  const updateAccountInfo = ApiHook.CallUpdateAccountInfo(updateBtn)
  const handleStripeAccount = () => {
    if (!payment.stripeValue) { setConnectToStripe(true) } else { setUpdateButton(true) }
  }
  const paymentMethods = (data) => {
    if(data){
      return data.map((item) => ({
        label: item?.name,
        value: item?.slug,
        id: item?.id,
      }));
    }
    
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setPaymentValue(value);
    setError(""); // Clear any previous errors
  };

  const toggleEditMode = () => {
    setIsEditModeEnabled(!isEditModeEnabled);
    setError(""); // Clear any previous errors
  };

  const changePaymentMethod = (item) => {
    setPaymentMethodType(item?.id);
    const value = item.id === 5 ? payment.stripeValue : item.id === 6 ? payment.paypalValue : ""
    setPaymentValue(value); // Clear the payment value when changing payment method
    setError(""); // Clear any previous errors
  };

  const onSubmit = () => {
    if (!paymentValue && (paymentMethodType === 5 || paymentMethodType === 6)) {
      setError("this_field_is_required");
      return;
    }

    let data = {
      paymentMethod: paymentMethodType,
      ...(paymentMethodType === 6 && { paypalAccount: paymentValue }),
      ...(paymentMethodType === 5 && { stripeAccount: paymentValue }),
    };
    paymentMutation.mutate(data, {
      onSuccess: (res) => {
        if (res.status) {
          queryClient.invalidateQueries({ queryKey: ["profile"] });
          toast.success(t(res?.data));
          setIsEditModeEnabled(false);
        } else {
          toast.error(t(res?.description));
        }
      },
      onError: (err) => {
        setError("Failed to update payment details. Please try again.");
      },
    });
  };

  return (
    <div id="fourthTab" className="tabcontent">
      <div className="editSec">
        <div className={`editSec ${isEditModeEnabled ? "disabled" : ""}`}>
          <span
            style={{ textDecoration: "none", cursor: "pointer" }}
            onClick={toggleEditMode}
          >
            <i className="fa-solid fa-pen-to-square" style={{ color: "#32009c" }}></i>
          </span>
        </div>
      </div>
      <h3>{t("paymentDetails")}</h3>
      <div className="tabcontent_form_section">
        {paymentMethodType === 5 && (
          <div className="mb-3 row tabBlockClass">
            <label className="col-sm-3 col-form-label labelWidthClass">
              {t("stripeAccount")}:
            </label>
            <div className="col-md-9 col-sm-12 col-12" >
              <div style={{ display: "flex" }}>

                <input
                  name="stripeAccount"
                  placeholder="Account ID"
                  type="text"
                  className="form-control"
                  value={payment.stripeValue}
                  // disabled={!isEditModeEnabled}
                  disabled={true}

                  onChange={handleChange}
                />
                {paymentMethodType === 5 &&
                  <div className={`paymenytLinkBtn ${isEditModeEnabled ? "disabled" : ""}`} style={{ marginLeft: "10px" }}>
                    <SubmitButton
                      id={"1"}
                      isSubmitting={!isEditModeEnabled}
                      click={handleStripeAccount}
                      text={payment.stripeValue ? "edit" : "connect"}
                      className="btn"
                    />
                  </div>}
              </div>
              {error && <span className="validation-error-message">{t(error)}</span>}
            </div>
          </div>
        )}
        {paymentMethodType === 6 && (
          <div className="mb-3 row tabBlockClass">
            <label className="col-sm-3 col-form-label labelWidthClass">
              {t("paypalAccount")}:
            </label>
            <div className="col-md-9 col-sm-12 col-12">
              <input
                name="paypalAccount"
                type="text"
                className="form-control"
                value={paymentValue}
                disabled={!isEditModeEnabled}
                onChange={handleChange}
              />
              {error && <p className="text-danger">{t(error)}</p>}
            </div>
          </div>
        )}
        <div className="mb-3 row tabBlockClass">
          <label className="col-sm-3 col-form-label labelWidthClass">
            {t("paymentMethod")}:
          </label>
          <div className="col-md-9 col-sm-12 col-12">
            <Select
              value={paymentMethods(payment?.options)?.find(
                (item) => item.id === paymentMethodType
              )}
              options={paymentMethods(payment?.options)}
              isDisabled={!isEditModeEnabled}
              onChange={changePaymentMethod}
            />
          </div>
        </div>
        <div className={`paymenytLinkBtn ${isEditModeEnabled ? "disabled" : ""}`}>

          <SubmitButton
            id={"1"}
            isSubmitting={!isEditModeEnabled}
            click={onSubmit}
            text="update"
            className="btn"
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentDetailsTab;