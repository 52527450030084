import React, { useState } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import GenealogyTreeWeb from "../../components/Web/GenealogyTreeWeb";
import WebTreeNavbar from "../../components/Web/WebTreeNavbar";
import { ApiHook } from "../../hooks/apiHook";

const GenealogyTreeWebView = () => {
  console.log("=======enter here======GenealogyTreeWebView============")
  const checkSubscriptionStatus = ApiHook.CallcheckSubscriptionStatus()
  const [selectedUserId, setSelectedUserId] = useState("");
  const [searchUsername, setSearchUsername] = useState("");
  const [doubleUser, setDoubleUser] = useState("");

  return (
    <>
      <WebTreeNavbar
        searchUsername={searchUsername}
        setSearchUsername={setSearchUsername}
      />
      <GenealogyTreeWeb
        selectedUserId={selectedUserId}
        setSelectedUserId={setSelectedUserId}
        doubleUser={doubleUser}
        setDoubleUser={setDoubleUser}
        searchUsername={searchUsername}
        setSearchUsername={setSearchUsername}
        subscriptionStatus={checkSubscriptionStatus?.data}
      />
    </>
  );
};

export default GenealogyTreeWebView;
