import React from 'react'
import NoData from './NoData'

export default function Banner({ data, copyToClipboard }) {
    return (
        <>
            <div className="border promotion-tabs" id="nav-banner" role="tabpanel" aria-labelledby="nav-banner-tab">
                <h4 className="heading_style_1">Banner Invites</h4>
                {data?.length > 0 ? data?.map((item) => {
                    return <>
                        <br />
                        <div className="row">
                            <div className="col-md-4">
                                <div className="banner_invite_img"><img src={item.content} /></div>
                            </div>
                            <div className="col-md-8">
                                <h6>{item.subject}</h6>
                                <div className="banner_image_content_area" disabled >
                                    {item.targetUrl}
                                </div>
                                <button className="btn btn-primary" onClick={() => copyToClipboard(item.targetUrl)}>Copy</button>

                            </div>
                        </div>
                    </>
                }) : <NoData />}

            </div>
        </>
    )
}
