import React, { useState } from 'react'
import EmailPopup from './EmailPopup'
import emailLogo from "../../assests/images/email.png";
import NoData from './NoData';


export default function EmailComponent({ data, mailhistory }) {
  const [popUp, setPopUp] = useState(false)
  const [selectedMail, setSelectedMail] = useState("")

  const setEmailPopup = (item) => {
    setSelectedMail(item)
    setPopUp(true)
  }
  return (
    <>
      <div className="border promotion-tabs" id="nav-email" role="tabpanel" aria-labelledby="nav-email-tab">
        <h4 className="heading_style_1">Email Invites</h4>
        <div className='invite-mail-history-body'>
          {data?.length > 0 ? data?.map((item) => {
            return (<div className="emailInviteRow">
              <div className="emailInvite_img">
                <img src={emailLogo} alt="" />
              </div>
              <div className="emailInviteText">
                <h4>{item.subject}</h4>
                {
                  <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                }
                <button type="submit" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#mailinvite" onClick={() => setEmailPopup(item)}>Preview</button>
              </div>

            </div>)
          }) : <NoData />
          }
        </div>

        <h4 className="heading_style_1 mt-5">Invites History</h4>
        <div className='invite-mail-history-body'>
          {mailhistory?.length > 0 ? mailhistory?.map((mail) => {
            return <>
              <div className="emailInviteRow">
                <div className="emailInvite_img">
                  <img src={emailLogo} alt="" />
                </div>
                <div className="emailInviteText">
                  <h4>{mail.subject}</h4>
                  <div dangerouslySetInnerHTML={{ __html: mail.message }}></div>
                  <p>{`To: ${mail.mailId}`}</p>
                </div>

              </div>
            </>
          }) : <NoData />}
        </div>
        {<EmailPopup popUp={popUp} setPopUp={setPopUp} selectedMail={selectedMail} />}
      </div>
    </>
  )
}
