import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ApiHook } from '../../hooks/apiHook';
import PromotionNavigation from '../../components/promotion/PromotionNavigation';
import { toast } from 'react-toastify';

export default function Promotions() {
    const { t } = useTranslation();
    const [tab, setTab] = useState("email")
    const [copied, setCopied] = useState(false);

    const data = ApiHook.CallPromotionDetails({ type: tab });
    const mailhistory = ApiHook.CallMailInviteHistory()

    const copyToClipboard = async (text) => {
        if (navigator && navigator.clipboard) {
            try {
                await navigator.clipboard.writeText(text);
                setCopied(true);
                toast.success("copied")
                setTimeout(() => {
                    setCopied(false);
                }, 2000);
            } catch (error) {
                console.error("Clipboard copy failed:", error);
            }
        } else {
            // Clipboard API is not supported, provide a fallback method
            try {
                const textArea = document.createElement("textarea");
                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.select();
                document.execCommand("copy");
                document.body.removeChild(textArea);
                setCopied(true);
                toast.success("copied")
                setTimeout(() => {
                    setCopied(false);
                }, 2000);
            } catch (error) {
                console.error("Fallback clipboard copy failed:", error);
            }
        }
    };

    //without tags
    // const copyToClipboard = async (text) => {
    //     // Create a temporary element to extract text content from HTML
    //     const tempElement = document.createElement("div");
    //     tempElement.innerHTML = text;
    //     const textToCopy = tempElement.innerText;
    
    //     if (navigator && navigator.clipboard) {
    //         try {
    //             await navigator.clipboard.writeText(textToCopy);
    //             setCopied(true);
    //             toast.success("Copied");
    //             setTimeout(() => {
    //                 setCopied(false);
    //             }, 2000);
    //         } catch (error) {
    //             console.error("Clipboard copy failed:", error);
    //         }
    //     } else {
    //         // Clipboard API is not supported, provide a fallback method
    //         try {
    //             const textArea = document.createElement("textarea");
    //             textArea.value = textToCopy;
    //             document.body.appendChild(textArea);
    //             textArea.select();
    //             document.execCommand("copy");
    //             document.body.removeChild(textArea);
    //             setCopied(true);
    //             toast.success("Copied");
    //             setTimeout(() => {
    //                 setCopied(false);
    //             }, 2000);
    //         } catch (error) {
    //             console.error("Fallback clipboard copy failed:", error);
    //         }
    //     }
    // };
    

    return (
        <>
            <div className="page_head_top">{t("promotion-tools")}</div>
            {data && <PromotionNavigation tab={tab} setTab={setTab} data={data} mailhistory={mailhistory} copyToClipboard={copyToClipboard}/>}
        </>

    )
}
