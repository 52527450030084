
//demo
const BASE_URL = "https://abraham.admin.iossmlm.com/node_api/";
const DEFAULT_KEY = 'j^sShDpG%H_e-BH((sFH*de-FDtHezjGg-mHJL)hDj*hg@Dv';
// const PAYPAL_CLIENT_ID = "AYrNMBKvqviLS8r8F5_pCD9cPgmrwJteBWItv2vxsrSA4Xmgc0fCCrL4stxAKQjVCw0fmDSSmTQyEbdF"
const PAYPAL_CLIENT_ID = "AXd0riNm4_SWML3hlZjVKqHTOy8mPqzQ9Z_ZcY-ZULkxMg4W9hIrWh4KXjo9_NekzBhx57VGvN5iofPD"

//local
// const BASE_URL = "http://192.168.21.2:5000/node_api/";
// const DEFAULT_KEY = 'j^sShDpG%H_e-BH((sFH*de-FDtHezjGg-mHJL)hDj*hg@Dv';
// // const PAYPAL_CLIENT_ID = "Ae_QomCe8wZC6KgrjMMtxhkbFna6Hbrhph2kJ148Zm8FGt4rvPsErHQLw8ZCOZd2twcnFA2kZq53Nwpm"
// const PAYPAL_CLIENT_ID = "AYrNMBKvqviLS8r8F5_pCD9cPgmrwJteBWItv2vxsrSA4Xmgc0fCCrL4stxAKQjVCw0fmDSSmTQyEbdF"



// http://192.168.21.2:5001/node_api/uploads/profile/1721124508719-GSmartremovebgpreview.png

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID }