import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import EmailComponent from './EmailComponent';
import Texts from './Texts';
import Banner from './Banner';
import Socials from './Socials';

export default function PromotionNavigation({ tab, setTab, mailhistory, data, copyToClipboard }) {

  return (
    <>
      {data && <div className="card p-3 promotion_tool_sec">
        <div className="tab-content bg-white " id="nav-tabContent">
          <Tabs
            defaultActiveKey="profile"
            id="uncontrolled-tab-example"
            activeKey={tab}
            className="mb-3"
            onSelect={(key) => setTab(key)}
          >
            <Tab eventKey="email" title="Email">
              <EmailComponent data={data?.data} mailhistory={mailhistory?.data}/>
            </Tab>
            <Tab eventKey="text" title="Text">
              <Texts data={data?.data} copyToClipboard={copyToClipboard} />
            </Tab>
            <Tab eventKey="banner" title="Banner">
              <Banner data={data?.data} copyToClipboard={copyToClipboard} />
            </Tab>
            <Tab eventKey="social" title="Social">
              <Socials data={data?.data} copyToClipboard={copyToClipboard} />
            </Tab>
          </Tabs>
        </div>
      </div>}
    </>
  )
}
