import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { ApiHook } from '../../hooks/apiHook';
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

export default function EmailPopup({ selectedMail, popUp, setPopUp }) {
    const queryClient = useQueryClient();
    const sendMail = ApiHook.CallSendInviteMail()

    const [recepient, setRecepient] = useState("")
    const [emailError, setEmailError] = useState('');

    const handleClose = () => {
        setPopUp(false)
        setEmailError("")
        setRecepient("")
    };
    const handleRecepient = async (e) => {
        setRecepient(e.target.value)
    }
    const handleInvite = async () => {
        if (!recepient.trim()) {
            setEmailError("Recepient email is required")
            return
        }
        const valid = await validateEmail()
        if (!valid) {
            setEmailError("Invalid email")
            return
        }
        setEmailError("")
        const payload = {
            mailId: selectedMail.id,
            email: recepient
        }
        const response = await sendMail.mutateAsync(payload)
        if (response.status) {
            setRecepient("")
            handleClose()
            toast.success("Invite Sent")
            setTimeout(() => {
                queryClient.refetchQueries({ queryKey: ["mail-invite-details"] });
            }, 5000);
        }
    }
    const validateEmail = async () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(recepient)) return false
        return true
    }

    return (
        <>

            <Modal show={popUp} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>EMAIL INVITE</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Recepient Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="example@gmail.com"
                                autoFocus
                                onChange={handleRecepient}
                                isInvalid={!!emailError}
                                value={recepient}
                            />
                            <Form.Control.Feedback type="invalid">
                                {`* ${emailError}`}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea0"
                        >
                            <Form.Label>Subject</Form.Label>
                            <Form.Control type="text" value={selectedMail.subject} disabled />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Content</Form.Label>
                            <div
                                className="form-control"
                                style={{ height: 'auto', minHeight: 'calc(1.5em + 0.75rem + 2px)', overflow: 'auto', backgroundColor: "#e7e7e7" }}
                                dangerouslySetInnerHTML={{ __html: selectedMail.content }}
                                disabled
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleInvite}>
                        Invite
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
