import React from 'react'
import NoData from './NoData'

export default function Texts({ data, copyToClipboard }) {
  return (
    <>
      <div className="border promotion-tabs" id="nav-text" role="tabpanel" aria-labelledby="nav-text-tab">
        <h4 className="heading_style_1">Text Invites</h4>
        {data?.length > 0 ? data?.map((item) => {
          return <>
            <div className="banner_image_content_area" dangerouslySetInnerHTML={{ __html: item.content }}></div>
            <button className="btn btn-primary" onClick={() => copyToClipboard(item.content)}>Copy</button>
          </>
        }) : <NoData />}
      </div>
    </>
  )
}
