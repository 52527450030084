import React from 'react'
import timerImg from '../../assests/images/4904579.jpg'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { ApiHook } from '../../hooks/apiHook'

function TribeRequest({ subscriptionStatus, userDetails }) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    console.log("=====location======", location)
    const checkSubscriptionStatus = ApiHook.CallcheckSubscriptionStatus()
    console.log("==checkSubscriptionStatus===", userDetails)
    return (
        <div className='tribeContainer'>
            <img className='w-25' src={timerImg} />
            {
                ((userDetails.regFrom === 0 && userDetails.subscriptionStatus === 0) || (userDetails.tribeStatus && (userDetails.subscriptionStatus == 0 || userDetails.oneYearPayment == 0))) ?
                    <div>
                        <h3>{t('subscribeInfo')}</h3>
                        <span>{t('tribeShortPara')}</span>
                    </div>
                    :
                    <div>
                        <h3>{t('tribeInfo')}</h3>
                        {
                            subscriptionStatus?.data?.subscriptionStatus == 1 ?
                                <span><i class="fa-solid fa-circle-info fa-beat-fade"></i> {t('waitingForpaymentConfirmation')}</span>
                                : <span>{t('tribeShortPara')}</span>
                        }

                    </div>
            }

            {subscriptionStatus?.data?.subscriptionStatus == 0 &&
                location.pathname.includes("genealogy-tree-web") ?

                <button className='subsccribeBTn' onClick={() => navigate('/subscribe-web')}>
                    {t("susbcribe")}</button>
                :
                <button className='subsccribeBTn' onClick={() => navigate('/renew')}>
                    {t("susbcribe")}</button>
            }

        </div>


    )
}

export default TribeRequest