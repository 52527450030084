import API from "../../api/api";

const callApi = async (endpoint) => {
    try {
        const response = await API.get(endpoint);
        if (response.status === 200) {
            return response?.data?.data;
        } else {
            return response?.data?.data;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const PromotionServices = {
    PromotionDetails: async ({ type }) => {
        return callApi(`get-promotion-details?type=${type}`);
    },
    MailInviteHistory: async () => {
        return callApi(`mail-invite-history`);
    },
    SendInviteMail: async (data) => {
        return API.post(`send-mail-invite`, data)
          .then((response) => response.data)
          .catch((error) => console.log(error.message));
      },
};

export default PromotionServices;
