import PromotionServices from "../../services/promotions/promotion";

export const PromotionDetails = async ({type}) => {
    try {
        const response = await PromotionServices.PromotionDetails({type});
        return response;
    } catch (error) {
        console.log(error);
        return error.message;
    }
};

export const MailInviteHistory = async () => {
    try {
        const response = await PromotionServices.MailInviteHistory();
        return response;
    } catch (error) {
        console.log(error);
        return error.message;
    }
};
export const SendInviteMail = async (data) => {
    try {
        const response = await PromotionServices.SendInviteMail(data);
        return response;
    } catch (error) {
        console.log(error);
        return error.message;
    }
};
