import React from 'react'
import NoData from './NoData'

export default function Socials({ data, copyToClipboard }) {
  return (
    <>
      <div className="border promotion-tabs" id="nav-social" role="tabpanel" aria-labelledby="nav-social-tab">
        {data?.length > 0 ?
          <>
            {data?.filter(item => item.type === 'social_facebook').length > 0 && <h4 className="heading_style_1">Facebook</h4>}
            {data?.filter(item => item.type === 'social_facebook').map((item) => {
              return <>
                <br />
                <div className="d-flex gap-2">
                  <div dangerouslySetInnerHTML={{ __html: item.content }} className='form-control' disabled></div>
                  <button className="btn btn-primary max-h-40"
                    onClick={() => copyToClipboard(item.content)}>Copy
                  </button>
                </div>
              </>
            })}
            {data?.filter(item => item.type === 'social_instagram').length > 0 && <h4 className="heading_style_1 mt-2">Instagram</h4>}
            {data?.filter(item => item.type === 'social_instagram').map((item) => {
              return <>
                <br />
                <div className="d-flex gap-2">
                  <div dangerouslySetInnerHTML={{ __html: item.content }} className='form-control' disabled></div>
                  <button className="btn btn-primary max-h-40" onClick={() => copyToClipboard(item.content)}>Copy</button>
                </div>
              </>
            })}

            {data?.filter(item => item.type === 'social_twitter').length > 0 && <h4 className="heading_style_1 mt-2">Twitter</h4>}
            {data?.filter(item => item.type === 'social_twitter').map((item) => {
              return <>
                <br />
                <div className="d-flex gap-2">
                  <div dangerouslySetInnerHTML={{ __html: item.content }} className='form-control' disabled></div>
                  <button className="btn btn-primary max-h-40" onClick={() => copyToClipboard(item.content)}>Copy</button>
                </div>
              </>
            })}
          </>
          :
          <NoData />
        }

      </div>
    </>
  )
}
